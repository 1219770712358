<template>
    <el-drawer
        title="选取地址"
        :visible.sync="drawerShow"
        :wrapperClosable="false"
        :destroy-on-close="true"
        size="auto"
        @close="close"
        append-to-body
    >
        <div class="drawer-content">
            <baidu-map :center="center" :zoom="zoom" ak='Y5qE51IMbh9Sdq2NAmMDUGGw' @ready="handler" @click="getLocationPoint" v-loading="loading">
                <bm-view class="bd-map"></bm-view>
                <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT"></bm-navigation>
                <bm-control class="bm-control">
                    <bm-auto-complete v-model="keyword" :sugStyle="{zIndex: 10000}" @confirm="confirmSearch">
                        <el-input v-model="keyword" placeholder="请输入地址来直接查找相关位置" size="medium"></el-input>
                    </bm-auto-complete>
                </bm-control>
                <bm-local-search :keyword="keyword" :auto-viewport="true" :panel="false" @searchcomplete="searchcomplete"></bm-local-search>
            </baidu-map>
            <div v-if="place" class="place">
                <span class="color-info">详细地址:</span> {{place.address}}
                (<span class="color-info">经度:</span> {{place.point.lng}} <span class="color-info">维度:</span> {{place.point.lat}})
            </div>
        </div>
        <div class="drawer-footer">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" @click="submitForm()"
                >确 定</el-button
            >
        </div>
    </el-drawer>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmView from 'vue-baidu-map/components/map/MapView.vue'
import BmControl from 'vue-baidu-map/components/controls/Control'
import BmAutoComplete from 'vue-baidu-map/components/others/AutoComplete'
import BmLocalSearch from 'vue-baidu-map/components/search/LocalSearch'
import BmNavigation from 'vue-baidu-map/components/controls/Navigation'

export default {
    components:{BaiduMap,BmView,BmControl,BmAutoComplete,BmLocalSearch,BmNavigation},
    data(){
        return{
            drawerShow: false,
            keyword:'',
            place:null,
            loading: false,
            center: {lng: 0, lat: 0},
            zoom: 3
        }
    },
    
    methods:{
        handler ({BMap, map}) {
            // console.log(BMap, map)
            this.center.lng = 116.404
            this.center.lat = 39.915
            this.zoom = 10
        },
        getLocationPoint(e){
            this.loading = true;
            // eslint-disable-next-line no-undef
            let geoCoder = new BMap.Geocoder();
            geoCoder.getLocation(e.point, res => {
                this.place = {...res,address: res.surroundingPois && res.surroundingPois.length > 0 ? res.surroundingPois[0].address : res.address};
                this.loading = false;
            })
        },
        confirmSearch(){
            this.loading = true;
        },
        searchcomplete(){
            this.loading = false;
        },


        close(){
            this.keyword = "";
            this.loading = false;
            this.drawerShow = false;
        },
        show(){
            this.drawerShow = true;
        },
        //提交
        submitForm() {
            if(this.place){
                this.$emit('update:address',this.place.address);
                this.$emit('update:lng',this.place.point.lng);
                this.$emit('update:lat',this.place.point.lat);
                this.close();
            }else{
               return this.$message.error('请在地图上选择取票地址'); 
            }
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-drawer{
    min-width: 60%;
    .bd-map {
        width: 100%;
        height: 65vh;
    }
    .bm-control{
        width: 100%;
        padding-bottom: 20px;
        background: #ffffff;
    }
}
.place{
    margin-top: 20px;
}
</style>